<template>
    <main>
        <div class="container">
            搜索结果页
        </div>
    </main>
</template>

<script>
export default {
    data() {
        return {}
    }
}
</script>